import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StyledBlockquote = styled(Box)(({ light, theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  borderLeft: `4px solid ${theme.palette.secondary.main}`,
  fontStyle: `italic`,
  fontSize: `24px`,
  color: `${light ? "white" : "black"}`,
}));

export { StyledBlockquote };
