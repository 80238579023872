import React from "react";
import PropTypes from "prop-types";

import { StyledBlockquote } from "./Blockquote.styled";

const Blockquote = ({ light, children }) => {
  return <StyledBlockquote light={light}>{children}</StyledBlockquote>;
};

Blockquote.propTypes = {
  light: PropTypes.bool,
  children: PropTypes.node,
};

export default Blockquote;
