import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";
import { Blockquote } from "../Blockquote";

import { StyledHandRejuvenation } from "./HandRejuvenation.styled";

const HandRejuvenationContent = ({ children }) => {
  return (
    <StyledHandRejuvenation>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Hand Rejuvenation
            </Typography>
            {/* <Typography variant="h5" align="center" mb={2}>
              <i>
                "Complete Hand Surgery - Care from Reconstruction to
                Rejuvenation"
              </i>
            </Typography> */}
            <Typography paragraph>
              Hands and nails display a wealth of information about a person. A
              person's hands can lead into very good insight about a person's
              occupation, expressive personality and age.
            </Typography>
            <Typography paragraph>
              We at The Joyner Hand take pride in your hands and we promote hand
              rejuvenation. Surgical and non-surgical techniques can help the
              appearance of your hands.
            </Typography>
            <Typography paragraph>
              Natural aging, exposure to sunlight, loss of fatty tissue, and age
              spots may lead you to hand rejuvenation treatments.
            </Typography>
            <Typography variant="h6">Moisturizers</Typography>
            <Typography paragraph>
              Our skin is also the first line of defense against external
              elements and provides effective protection from the harsh
              substances of the environment we live and work in. Moisturizing
              your hands can be very beneficial. A combination of a hand
              sanitizer and moisturizer also offers protection against harmful
              germs and bacteria. Let's keep our hands healthy and protected!
            </Typography>
            <Typography variant="h6">Nail Care</Typography>
            <Typography paragraph>
              Nails can provide good information about a persons state of
              health. Keep your nails strong and healthy. Eating the right
              foods, staying hydrated and simply treating your nails with "TLC"
              makes a difference.
            </Typography>
            <Typography variant="h6">Sun Protection</Typography>
            <Typography paragraph>
              Sun protection is a must. Sun induced skin damage on the hands is
              preventable. Make sure that you are using sunscreen!
            </Typography>
            <Typography variant="h6">Chemical Peals</Typography>
            <Typography paragraph>
              Chemical peels can rejuvenate the hands by removing the top layers
              of the skin and stimulating growth of new, healthy - looking skin.
              This resurfacing procedure will help the skin feel softer and
              smoother.
            </Typography>
            <Typography variant="h6">Sclerotherapy</Typography>
            <Typography paragraph>
              Reduce the appearance of veins through our sclerosing solution.
              This chemical solution causes the veins to turn white and
              gradually disappear. The image below shows a before and after
              sclerotherapy.
            </Typography>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="sm">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <StaticImage
                    src="../../images/spa.jpg"
                    alt="young old hand"
                  />
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    "The hands are a window to one's heart and soul." - MSJ
                  </Blockquote>
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    By a persons fingernails... by the callosities of his
                    forefinger and thumb... by each of these things an
                    individual's calling is revealed." - Sherlock Holmes
                  </Blockquote>
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    "The hand is the extension of the brain." - Anaxagoras
                  </Blockquote>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledHandRejuvenation>
  );
};

const HandRejuvenation = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-hand-rejuv.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="secondary">
              <strong> Hand Rejuvenation</strong>
            </Typography>
            <Typography variant="h4" align="center" color="secondary">
              Your hands deserve it
            </Typography>
          </Box>
        </Box>
      </BgImage>
      <HandRejuvenationContent />
    </>
  );
};

export default HandRejuvenation;
