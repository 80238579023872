import * as React from "react";
import { Layout } from "../components/Layout";
import { HandRejuvenation } from "../components/HandRejuvenation";

export default function HandRejuvenationPage() {
  return (
    <Layout>
      <HandRejuvenation />
    </Layout>
  );
}
